import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../components/authentication/Default'
import { Button } from '../../../components/common/button/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Select } from '../../../components/common/select/Select'
import { skillsWithLabelValue as SKILLS } from '../../../data/custom-options'
import { useState } from 'react'

const INFO = {
    title: <>What work are you <br /> here to do?</>,
    description: 'Your skills show clients what you can offer'
}

const customStyles = {
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#F8F8FD',
        padding: '4px',
        gap: 4
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#920AF2',
        padding: 0,
        paddingLeft: 0
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#920AF2',
    }),
    menu: (provided) => ({
        ...provided,
        boxShadow: '5px 5px 50px 0px rgba(26, 32, 44, 0.06)'
    }),
    menuList: (provided) => ({
        ...provided,
        scrollbarWidth: 'none'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#0000',
        color: state.isFocused ? 'black' : '#667185',
        ':active': {
            backgroundColor: '#0000', // '#920AF2'
        }
    })
}

export const SkillsInputSection = () => {

    const providerDetails = JSON.parse(window.localStorage.getItem('PROVIDER_DETAILS')) || {}

    const navigate = useNavigate()
    const location = useLocation()

    const { email } = location.state || {}

    const initialSelectedSkills = providerDetails?.skills?.map(selectedSkill => SKILLS.find(skill => skill.value === selectedSkill))

    const [selectedSkills, setSelectedSkills] = useState(initialSelectedSkills || [])
    const [skillError, setSkillError] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()

        setSkillError('');

        if (selectedSkills.length === 0) return setSkillError('Skills is required')

        var data = {}

        var formData = new FormData(e.target)

        const skillsArray = []
        formData.forEach((val, _) => skillsArray.push(val))

        data['skills'] = skillsArray

        window.localStorage.setItem('PROVIDER_DETAILS', JSON.stringify({ ...providerDetails, ...data }))

        navigate('/profile/bio', { state: { ...location?.state, ...data } })
    }

    return (
        <ProfileWrapper email={email} info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Select
                    label='Your skills'
                    name='skills'
                    inputId='skills-select'
                    htmlFor='skills-select'
                    placeholder='Start typing to search for skills'
                    options={SKILLS}
                    value={selectedSkills}
                    onChange={(selectedOptions) => setSelectedSkills(selectedOptions)}
                    className='mb-3'
                    isMulti
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    newStyles={customStyles}
                    newControlStyle={{ cursor: 'text', paddingBlock: 14 }}
                    error={skillError}
                />
                {selectedSkills?.length === 0 ? (
                    <Button type='submit' disabled className='w-100 py-3'>Now, pitch yourself</Button>
                ) : (
                    <Stack direction='horizontal' gap={4}>
                        <Link
                            to='/profile/photo-location'
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony text-center py-3'
                        >
                            Back
                        </Link>
                        <Button type='submit' className='w-100 py-3'>Continue</Button>
                    </Stack>
                )}
            </Form>
        </ProfileWrapper>
    )
}
