import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../components/authentication/Default'
import { Button } from '../../../components/common/button/Button'
import { formatFileSize, showToaster } from '../../../utils/utils'
import { IoCloseOutline } from 'react-icons/io5'
import { ReactComponent as PDF } from '../../../assets/images/PDF.svg'
import { ReactComponent as UserCircle } from '../../../assets/images/user.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PostRequest from '../../../services/PostRequest'

export const SubmitProfile = () => {

    const providerDetails = window.localStorage.getItem('PROVIDER_DETAILS')
    const parsedProviderDetails = JSON.parse(providerDetails) || {}

    const navigate = useNavigate()
    const location = useLocation()

    const { resume, profileImage } = location.state || {}

    const [imageSrc, setImageSrc] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault()

        const data = {
            ...parsedProviderDetails,
            profileImage: profileImage || '',
            resume: resume || ''
        }

        try {
            const response = await PostRequest('/provider/register', data, { isMultiMedia: true })

            console.log('response', response)
            if (providerDetails) {
                window.localStorage.removeItem('PROVIDER_DETAILS')
            }
            showToaster('Provider Registered Successfully!')

            navigate('/login')
        } catch (error) {
            console.log('error', error)
            showToaster(error.message, 'error')
        }
    }

    useEffect(() => {
        if (location.state && location.state.profileImage) {
            const file = location.state.profileImage;
            if (file?.name !== '' && file?.size !== 0) {
                setImageSrc(URL.createObjectURL(file));
            }
        }
    }, [location.state])

    return (
        <ProfileWrapper>
            <h5 className='welcome-heading line-height-125 text-center mb-3'>Submit your profile</h5>
            <Form onSubmit={handleSubmit}>
                <Stack direction='horizontal' gap={4} className='mb-3'>
                    {imageSrc ? <img
                        src={imageSrc}
                        alt='Uploaded'
                        className='rounded-circle object-fit-cover'
                        style={{ height: 96, width: 96 }}
                    /> : <UserCircle />}
                    <div>
                        <h6 className='profile_label line-height-125 mb-1'>{parsedProviderDetails?.firstName} {parsedProviderDetails?.lastName}</h6>
                        <p className='mb-1 profile_timezone'>{parsedProviderDetails?.state}, {parsedProviderDetails?.country}</p>
                        <p className='mb-1 profile_timezone'>11:23AM Local Time</p>
                        <Button
                            type='button'
                            // onClick={() => navigate('/profile/photo-location')}
                            variant='outline'
                            className='py-2 font-12 rounded fw-medium border'
                        >
                            Edit
                        </Button>
                    </div>
                </Stack>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>SSN</span>
                        <span className='color-primary fw-semibold profile_timezone cursor-pointer'>Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <span className='form-label'>{parsedProviderDetails?.ssn || 288290021}</span>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>My Bio</span>
                        <span className='color-primary fw-semibold profile_timezone cursor-pointer'>Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <span className='form-label'>
                        {parsedProviderDetails?.bio}
                    </span>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>Your skills</span>
                        <span className='color-primary fw-semibold profile_timezone cursor-pointer'>Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className='flex-wrap' gap={2}>
                        {parsedProviderDetails?.skills?.map((skill, i) => {
                            return <span className='skill' key={i}>{skill}</span>
                        })}
                    </Stack>
                </div>
                <div className='mb-3'>
                    <Stack direction='horizontal' className='justify-content-between'>
                        <span className='profile_label'>My resume</span>
                        <span
                            className='color-primary fw-semibold profile_timezone cursor-pointer'
                            // onClick={() => navigate('/profile/resume-uploader')}
                        >Edit</span>
                    </Stack>
                    <hr className='my-2' />
                    <Stack direction='horizontal' className=' justify-content-between mb-3'>
                        <PDF className='flex-shrink-0' />
                        <div className='me-auto ms-2'>
                            <p className='mb-0 pdf-title text-truncate' style={{ maxWidth: 200 }}>{resume ? resume.name : parsedProviderDetails?.resume?.name}</p>
                            <span className='pdf-size'>{formatFileSize(resume ? resume.size : parsedProviderDetails?.resume?.size)}</span>
                        </div>
                        <div className='pdf-close__wrapper'>
                            <IoCloseOutline size={20} />
                        </div>
                    </Stack>
                </div>
                <Button type='submit' disabled={false} className='w-100 py-3'>Submit profile</Button>
            </Form>
        </ProfileWrapper>
    )
}
