import OTPInput from 'react-otp-input'
import './my-otp-input.css'

export const MyOTPInput = ({ label, wrapperClasses, error, ...rest }) => {
    return (
        <div className={`mb-3 otp-input__wrapper ${wrapperClasses}`}>
            <label htmlFor='otp-input-0' className='form-label line-height-160'>{label}</label>
            <OTPInput
                numInputs={6}
                placeholder='------'
                containerStyle={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
                renderInput={(props, i) => <input name='otp' id={`otp-input-${i}`} {...props} />}
                {...rest}
            />
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    )
}
