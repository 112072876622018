import { Col, Container, Row } from 'react-bootstrap'
import { Testimonial } from './Testimonial'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import { IoCloseOutline } from 'react-icons/io5'
import { FaRegUser } from 'react-icons/fa'
import { Sidebar } from '../common/sidebar/Sidebar'
import { Header } from '../common/header/Header'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProviderDetails } from '../../store/provider/provider.action'
import { Stepper } from '../common/Stepper'
import { useLocation } from 'react-router-dom'

export const AuthWrapper = ({ children }) => {
    return (
        <div className='my-3 px-4'>
            <Row className='gap-5'>
                <Col md={5}>
                    <Testimonial />
                </Col>
                <Col as={Row} className='px-0 align-items-center justify-content-center wrapper__right' md={6}>
                    <div style={{ width: '65%' }}>
                        {children}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export const ResetPasswordWrapper = ({ children }) => {
    return (
        <Container className='mt-3'>
            <Row className='justify-content-between align-items-center'>
                <Col xs={5} sm={4} md={3} className='p-2'><Logo /></Col>
                <Col xs={2} className='text-end'><IoCloseOutline size={24} /></Col>
            </Row>
            <hr className='border-botticelli' />
            <Row className='justify-content-center align-items-center' style={{ height: 'calc(100vh - 118px)' }}>
                <Col xs={11} sm={8} md={4}>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

export const ProfileWrapper = ({ children, email, info }) => {

    const location = useLocation()

    const providerDetails = JSON.parse(window.localStorage.getItem('PROVIDER_DETAILS')) || {}

    const steps = [
        '/profile/tax-information',
        '/profile/photo-location',
        '/profile/resume-uploader',
        '/profile/skills',
        '/profile/bio',
    ]

    return (
        <Container className='mt-3'>
            <div className='p-2 my-2'><Logo /></div>
            <Row className='justify-content-center'>
                <Col xs={11} sm={8} md={4}>
                    {steps.includes(location.pathname) && <Stepper steps={steps} currentStep={location.pathname} />}
                </Col>
            </Row>
            <Row className='justify-content-center align-items-center overflow-y-auto' style={{ height: 'calc(100vh - 118px)', scrollbarWidth: 'none' }}>
                <Col xs={11} sm={8} md={4}>
                    {email && <div className='user-profile-card'>
                        <span>{providerDetails?.email}</span>
                        <FaRegUser size={12} className='text-black ms-1' />
                    </div>}
                    {info && <div className='text-center'>
                        <h5 className='welcome-heading line-height-125'>{info?.title}</h5>
                        <p className='font-14 fw-medium line-height-160 color-grey-500 mb-3'>
                            {info?.description}
                        </p>
                    </div>}
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

export const MainDefault = ({ children }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProviderDetails())
    }, [])

    return <div className='d-flex overflow-x-hidden' id='wrapper'>
        <Sidebar />
        <div id='page-content-wrapper'>
            <Header />
            <Container fluid>
                {children}
            </Container>
        </div>
    </div>
}