export const taxOptions = [
    { label: 'Social Security Number (SSN/TIN)', value: 'Social Security Number (SSN/TIN)' },
    { label: 'EIN', value: 'EIN' },
]

export const taxTypeOptions = [
    { label: 'Sole proprietorship', value: 'Sole proprietorship' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Limited liability company (LLC)', value: 'Limited liability company (LLC)' },
    { label: 'Corporation', value: 'Corporation' },
]

export const titleOptions = [
    { label: 'Shareholder', value: 'shareholder' },
    { label: 'CEO', value: 'ceo' },
    { label: 'President', value: 'president' },
    { label: 'Treasurer', value: 'treasurer' },
    { label: 'Secretary', value: 'secretary' },
    { label: 'Member', value: 'member' },
    { label: 'Partner', value: 'partner' },
    { label: 'Owner', value: 'owner' },
]

const SKILLS = [
    'JavaScript',
    'React',
    'NodeJs',
    'CSS',
    'HTML',
    'Python',
    'Java',
    'Ruby',
    'C++',
    'TypeScript',
    'Git',
    'Docker',
    'AWS'
];

export const skillsWithLabelValue = SKILLS.map(skill => ({
    label: skill,
    value: skill.toLowerCase().replace(/\s+/g, '_') // or any other transformation you need
}))