import { FcGoogle } from 'react-icons/fc'
import './sign-up.css'
import { Form } from 'react-bootstrap'
import { FaApple } from 'react-icons/fa'
import { Input } from '../../../components/common/input/Input'
import { Button } from '../../../components/common/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AuthWrapper } from '../../../components/authentication/Default'
// import { useGoogleLogin } from '@react-oauth/google'
// import { showToaster } from '../../../utils/utils'
// import PostRequest from '../../../services/PostRequest'

export const WelcomeToZero = () => {

    const providerDetails = window.localStorage.getItem('PROVIDER_DETAILS')
    const parsedProviderDetails = JSON.parse(providerDetails) || {}

    const navigate = useNavigate()

    const [email, setEmail] = useState(parsedProviderDetails?.email || '');
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!value) return setError('Email address is required');

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(value)) return setError('Please enter a valid email address');

        if (value) setError('');
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError('');

        if (!email) return setError('Email address is required');

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(email)) return setError('Please enter a valid email address');

        var data = {}
        const formData = new FormData(e.target)
        formData.forEach((val, key) => data[key] = val)

        if (providerDetails) {
            window.localStorage.removeItem('PROVIDER_DETAILS')
        }
        window.localStorage.setItem('PROVIDER_DETAILS', JSON.stringify(data))

        setEmail('');
        navigate('/account-setup', { state: email })
    }

    // const handleLoginWithGoogle = async (resp) => {
    //     console.log('resp', resp)
    //     try {
    //         const response = await PostRequest('/provider/login/google', { token: resp.credential })

    //         console.log('response', response)
    //         navigate('/dashboard')
    //     } catch (error) {
    //         showToaster(error.message, 'error')
    //     }
    // }

    const isEmailValid = email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    // const login = useGoogleLogin({
    //     onSuccess: (response) => {
    //         handleLoginWithGoogle(response)
    //     },
    //     onError: () => {
    //         console.log('Login Failed')
    //     }
    // })

    return (
        <AuthWrapper>
            <div className='text-center'>
                <p className='get-started line-height-125 color-grey-500'>GET STARTED</p>
                <h5 className='welcome-heading line-height-125 mb-3'>Welcome to Zero</h5>
            </div>
            <button
                className='button button__google'
            // onClick={login}
            >
                <FcGoogle size={22} />
                <span className='line-height-150'>Sign up with Google</span>
            </button>
            <button className='button button__apple'>
                <FaApple size={22} />
                <span className='line-height-150'>Sign up with Apple</span>
            </button>
            <div className='or-register-with-container'>
                <hr className='left-line' />
                <span className='or-text line-height-160'>Or register with</span>
                <hr className='right-line' />
            </div>
            <Form onSubmit={handleSubmit}>
                <Input
                    label='Email Address'
                    name='email'
                    id='email'
                    htmlFor='email'
                    type='email'
                    autoComplete='email'
                    placeholder='example@company.com'
                    value={email}
                    onChange={handleEmailChange}
                    error={error}
                    required
                    className='mb-3'
                />
                <Button type='submit' disabled={!isEmailValid} className='w-100 mb-3'>Continue with email</Button>
            </Form>
            <div className='login-text line-height-160 color-secondary'>
                Already have an account? {' '}
                <Link to='/login' className='text-decoration-none color-primary'>Login Here</Link>
            </div>
        </AuthWrapper>
    )
}
