import PostRequest from '../../services/PostRequest';

export const FETCH_PROVIDER_DETAILS = 'FETCH_PROVIDER_DETAILS'

export const fetchProviderDetails = () => {
    return async (dispatch) => {
        try {
            const response = await PostRequest('/provider/profile');
            dispatch({
                type: FETCH_PROVIDER_DETAILS,
                value: response
            })
        } catch (error) {
            console.log('error', error)
        }
    }
}