import { Col, Row } from 'react-bootstrap'

export const Stepper = ({ steps, currentStep }) => {
    const currentIndex = steps.indexOf(currentStep);
    return (
        <Row className='align-items-center justify-content-center gap-2'>
            {steps?.map((step, i) => {
                return <Col
                    key={i}
                    className='w-100'
                    style={{
                        height: 4,
                        backgroundColor: i <= currentIndex ? '#920AF2' : '#D6DDEB',
                    }}
                />
            })}
        </Row>
    )
}
