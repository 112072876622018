import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { WelcomeToZero } from './pages/authentication/sign-up/WelcomeToZero';
import { AccountSetup } from './pages/authentication/sign-up/AccountSetup';
import { Login } from './pages/authentication/login/Login';
import { IdentityConfirmation } from './pages/authentication/identity-confirmation/IdentityConfirmation';
import { EmailOTPVerification } from './pages/authentication/identity-confirmation/EmailOTPVerification';
import { AuthenticatorCodeVerification } from './pages/authentication/identity-confirmation/AuthenticatorCodeVerification';
import { ForgotPassword } from './pages/authentication/forgot-password/ForgotPassword';
import { ResetPasswordOTP } from './pages/authentication/forgot-password/ResetPasswordOTP';
import { ConfirmationMail } from './pages/authentication/forgot-password/ConfirmationMail';
import { ResetPassword } from './pages/authentication/forgot-password/ResetPassword';
import { ResetPasswordSuccess } from './pages/authentication/forgot-password/ResetPasswordSuccess';
import { GetStarted } from './pages/authentication/profiling/GetStarted';
import { TaxInformation } from './pages/authentication/profiling/TaxInformation';
import { PhotoAndLocation } from './pages/authentication/profiling/PhotoAndLocation';
import { ResumeUploader } from './pages/authentication/profiling/ResumeUploader';
import { SkillsInputSection } from './pages/authentication/profiling/SkillsInputSection';
import { BioInputSection } from './pages/authentication/profiling/BioInputSection';
import { SubmitProfile } from './pages/authentication/profiling/SubmitProfile';
import { Dashboard } from './pages/dashboard/Dashboard';
import { FindWork } from './pages/find-work/FindWork';
import { MyWork } from './pages/my-work/MyWork';
import { Payments } from './pages/payments/Payments';
import { Calendar } from './pages/calendar/Calendar';
import { MyAgreements } from './pages/my-agreements/MyAgreements';
import { Support } from './pages/support/Support';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/welcome' />} />
      <Route path='/welcome' element={<WelcomeToZero />} />
      <Route path='/account-setup' element={<AccountSetup />} />
      <Route path='/login' element={<Login />} />
      <Route path='/confirm-identity' element={<IdentityConfirmation />} />
      <Route path='/verify-email-otp' element={<EmailOTPVerification />} />
      <Route path='/verify-authenticator-code' element={<AuthenticatorCodeVerification />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password-otp' element={<ResetPasswordOTP />} />
      <Route path='/confirmation-mail' element={<ConfirmationMail />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/reset-password-success' element={<ResetPasswordSuccess />} />
      <Route path='/profile/get-started' element={<GetStarted />} />
      <Route path='/profile/tax-information' element={<TaxInformation />} />
      <Route path='/profile/photo-location' element={<PhotoAndLocation />} />
      <Route path='/profile/resume-uploader' element={<ResumeUploader />} />
      <Route path='/profile/skills' element={<SkillsInputSection />} />
      <Route path='/profile/bio' element={<BioInputSection />} />
      <Route path='/profile/submit' element={<SubmitProfile />} />

      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/find-work' element={<FindWork />} />
      <Route path='/my-work' element={<MyWork />} />
      <Route path='/payments' element={<Payments />} />
      <Route path='/calendar' element={<Calendar />} />
      <Route path='/my-agreements' element={<MyAgreements />} />
      <Route path='/support' element={<Support />} />
    </Routes>
  )
}
