// import { Form } from 'react-bootstrap'
import ReactSelect from 'react-select'
import './select.css'

export const Select = ({ htmlFor, className, label, error, options, newStyles, newControlStyle, ...rest }) => {

    const customStyles = {
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'black',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        container: (provided) => ({
            ...provided,
            display: 'block',
            color: '#0F172A',
            fontSize: '14px',
            fontWeight: 500
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '10px',
            borderColor: state.isFocused || state.menuIsOpen ? '#920AF2' : '#E2E8F0',
            padding: '10px',
            boxShadow: 'none',
            ':hover': {
                borderColor: state.menuIsOpen ? '#920AF2' : '#E2E8F0',
            },
            ...newControlStyle
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#94A3B8',
            fontWeight: 400
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#920AF2' : '#0000',
            color: state.isFocused ? 'white' : 'black',
            ':active': {
                backgroundColor: '#920AF2',
            }
        })
    };

    return (
        <div className={className}>
            <label className='form-label line-height-160' htmlFor={htmlFor || ''}>{label}</label>
            <ReactSelect options={options} menuPlacement='auto' isSearchable={false} styles={{ ...customStyles, ...newStyles }} {...rest} />
            {/* <Form.Select className='form-input' {...rest}>
                <option className='d-none'>Select one</option>
                {options?.map(opt => {
                    return <option value={opt.value} key={opt.value}>{opt.label}</option>
                })}
            </Form.Select> */}
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    )
}
