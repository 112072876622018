import { ListGroup } from 'react-bootstrap'
import './sidebar.css'

import { ReactComponent as Logo } from '../../../assets/images/logo.svg'

import { SIDEBAR_ITEMS } from '../../../data/constants'
import { Link, useLocation } from 'react-router-dom'

const NavItem = ({ to, icon: Icon, label, paths, ...rest }) => {
    const location = useLocation()
    const isActive = location.pathname === to || paths?.includes(location.pathname);

    return (
        <Link
            to={to}
            className={`border-0 list-group-item list-group__item ${isActive ? 'list-group__item-active' : ''}`}
            {...rest}
        >
            <Icon style={{ [to === '/my-work' || to === '/support' ? 'stroke' : 'fill']: isActive ? '#920AF2' : '#475367' }} />
            <span className={`ms-2 ${isActive ? 'color-primary' : 'color-fiord'}`}>{label}</span>
        </Link>
    )
}

export const Sidebar = () => {
    return (
        <div className='border-end bg-white' id='sidebar-wrapper'>
            <div className='sidebar-heading'>
                <Logo />
            </div>
            <ListGroup variant='flush'>
                {SIDEBAR_ITEMS.map(item => {
                    return <NavItem key={item.to} {...item} />
                })}
            </ListGroup>
        </div>
    )
}
