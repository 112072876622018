import { toast } from 'react-toastify';

export function formatFileSize(bytes = 0) {
    if (bytes === 0) return '0 B';

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const power = Math.floor(Math.log(bytes) / Math.log(1024));

    const formattedSize = (bytes / Math.pow(1024, power)).toFixed(2);

    return `${formattedSize} ${units[power]}`;
}

export const showToaster = (message, type = "success") => {
    toast(message, {
        type: type,
    });
};