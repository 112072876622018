import { ProfileWrapper } from '../../../components/authentication/Default'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Select } from '../../../components/common/select/Select'
import { Button } from '../../../components/common/button/Button'
import { Input } from '../../../components/common/input/Input'
import { taxOptions, taxTypeOptions, titleOptions } from '../../../data/custom-options'
import { useEffect, useState } from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router-dom'

const INFO = {
    title: 'Tax Information',
    description: 'Getting your account more personalized'
}

export const TaxInformation = () => {

    const providerDetails = JSON.parse(window.localStorage.getItem('PROVIDER_DETAILS')) || {}

    const navigate = useNavigate()
    const location = useLocation();

    const { firstName: initialFirstName, lastName: initialLastName } = location.state || {}

    const [formData, setFormData] = useState({
        selectedTax: taxOptions.find(option => option.value === providerDetails?.tax) || null,
        selectedTaxType: taxTypeOptions.find(option => option.value === providerDetails?.taxType) || null,
        selectedTitle: titleOptions.find(option => option.value === providerDetails?.title) || null,
        firstName: initialFirstName || providerDetails?.firstName || '',
        middleName: providerDetails?.middleName || '',
        lastName: initialLastName || providerDetails?.lastName || '',
        phoneNumber: providerDetails?.phoneNumber || '',
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        var data = {}

        const formData = new FormData(e.target)

        formData.forEach((val, key) => data[key] = val)

        window.localStorage.setItem('PROVIDER_DETAILS', JSON.stringify({ ...providerDetails, ...data }))

        navigate('/profile/photo-location', { state: { ...providerDetails, ...data } })
    }

    useEffect(() => {
        // Optionally, handle changes to firstName and lastName from location.state dynamically
        if (initialFirstName) setFormData(prev => ({ ...prev, firstName: initialFirstName }))
        if (initialLastName) setFormData(prev => ({ ...prev, lastName: initialLastName }))
    }, [initialFirstName, initialLastName])

    const renderTooltip = (props) => (
        <Tooltip {...props}>
            Social Security Number (SSN)/TIN
        </Tooltip>
    )

    return (
        <ProfileWrapper email info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Select
                    label='Select Tax'
                    name='tax'
                    inputId='tax'
                    htmlFor='tax'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxOptions}
                    value={formData?.selectedTax}
                    onChange={(tax) => setFormData(prev => ({ ...prev, selectedTax: tax }))}
                />
                {formData?.selectedTax?.value === 'Social Security Number (SSN/TIN)' && <Input
                    label={<>
                        Social Security Number (SSN)/TIN {' '}
                        <OverlayTrigger overlay={renderTooltip}>
                            <span>
                                <HiInformationCircle
                                    className='color-grey-500'
                                    size={20}
                                />
                            </span>
                        </OverlayTrigger>
                    </>}
                    placeholder='your ssn'
                    name='ssn'
                    id='ssn'
                    htmlFor='ssn'
                    className='mb-2'
                />}
                <Select
                    label='Select Tax Type'
                    name='taxType'
                    inputId='taxType'
                    htmlFor='taxType'
                    placeholder='Select one'
                    className='mb-2'
                    options={taxTypeOptions}
                    value={formData?.selectedTaxType}
                    onChange={(taxType) => setFormData(prev => ({ ...prev, selectedTaxType: taxType }))}

                />
                <Select
                    label='Title'
                    name='title'
                    htmlFor='title'
                    inputId='title'
                    placeholder='Select one'
                    className='mb-2'
                    options={titleOptions}
                    value={formData?.selectedTitle}
                    onChange={(title) => setFormData(prev => ({ ...prev, selectedTitle: title }))}
                />
                <Input
                    label='First name'
                    placeholder='First name'
                    htmlFor='firstName'
                    id='firstName'
                    name='firstName'
                    value={formData?.firstName}
                    onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
                    className='mb-2'
                />
                <Input
                    label='Middle name'
                    placeholder='Middle name'
                    name='middleName'
                    htmlFor='middleName'
                    id='middleName'
                    value={formData?.middleName}
                    onChange={(e) => setFormData(prev => ({ ...prev, middleName: e.target.value }))}
                    className='mb-2'
                />
                <Input
                    label='Last name'
                    placeholder='Last name'
                    name='lastName'
                    htmlFor='lastName'
                    id='lastName'
                    value={formData?.lastName}
                    onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                    className='mb-2'
                />
                <Input
                    label='Phone Number'
                    name='phoneNumber'
                    htmlFor='phoneNumber'
                    id='phoneNumber'
                    placeholder='01-1199929292'
                    value={formData?.phoneNumber}
                    onChange={(e) => {
                        const phoneNumber = e.target.value.replace(/[^0-9]/g, '')
                        setFormData(prev => ({ ...prev, phoneNumber }))
                    }}
                    className='mb-3'
                />
                <Button
                    type='submit'
                    className='w-100 mb-1 py-3'
                >
                    Continue
                </Button>
            </Form>
        </ProfileWrapper>
    )
}
