import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import './input.css'
import { useState } from 'react'

export const Input = ({ htmlFor, label, type, className, error, multiline, ...rest }) => {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    return (
        <div className={className}>
            <label className='form-label line-height-160' htmlFor={htmlFor || ''}>{label}</label>
            {multiline ? (
                <textarea className={`form-input line-height-160 ${error && 'form-error'}`} style={{ resize: 'none' }} {...rest} />
            ) : (
                <>
                    {type === 'password' ? (
                        <div className='position-relative'>
                            <input className={`form-input line-height-160 ${error && 'form-error'}`} type={isPasswordVisible ? 'text' : 'password'} {...rest} />
                            <span className='password-toggle-icon color-secondary' onClick={() => setIsPasswordVisible(prev => !prev)}>
                                {isPasswordVisible ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
                            </span>
                        </div>
                    ) : (
                        <input className={`form-input ${error && 'form-error'}`} type={type} {...rest} />
                    )}
                </>
            )}
            {error && <span className='error-text line-height-160 mt-2'>{error}</span>}
        </div>
    )
}