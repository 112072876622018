import { Form } from 'react-bootstrap'
import { Button } from '../../../components/common/button/Button'
import { useState } from 'react';
import { AuthWrapper } from '../../../components/authentication/Default';
import { useNavigate } from 'react-router-dom';
import { MyOTPInput } from '../../../components/common/my-otp-input/MyOTPInput';

export const AuthenticatorCodeVerification = () => {

  const navigate = useNavigate()

  const [otp, setOtp] = useState();
  const [error, setError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault()

    setError('');

    if (!otp) return setError('OTP is required');

    if (otp?.length === 6) {
      navigate('/dashboard')
    } else {
      return setError('Please enter a valid 6-digit OTP')
    }
  }

  return (
    <AuthWrapper>
      <h5 className='welcome-heading'>Confirm Identity</h5>
      <p className='text-black mb-4'>Open your authenticator on your phone and generate your login code</p>
      <Form onSubmit={handleSubmit}>
        <MyOTPInput
          value={otp}
          onChange={setOtp}
          error={error}
          label='Enter code generated'
        />
        <Button type='submit' className='w-100 mb-3'>Verify</Button>
      </Form>
      <Form.Check
        type='checkbox'
        label='Always remember this device'
      />
    </AuthWrapper>
  )
}
