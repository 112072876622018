import { ReactComponent as Dashboard } from '../assets/images/sidebar/elements.svg'
import { ReactComponent as Users } from '../assets/images/sidebar/users.svg'
import { ReactComponent as MyWork } from '../assets/images/sidebar/briefcase.svg'
import { ReactComponent as Payments } from '../assets/images/sidebar/card.svg'
import { ReactComponent as MyAgreements } from '../assets/images/sidebar/suitcase.svg'
import { FiFileText, FiHelpCircle, FiHome, FiSettings, FiHelpCircle as Support } from 'react-icons/fi'
import { ReactComponent as Pin } from '../assets/images/pin.svg'
// import { ReactComponent as Support } from '../assets/images/sidebar/question-circle.svg'

export const SIDEBAR_ITEMS = [
    {
        to: '/dashboard',
        icon: Dashboard,
        label: 'Dashboard',
        paths: []
    },
    {
        to: '/find-work',
        icon: Users,
        label: 'Find Work',
        paths: []
    },
    {
        to: '/my-work',
        icon: MyWork,
        label: 'My Work',
        paths: []
    },
    {
        to: '/payments',
        icon: Payments,
        label: 'Payments',
        paths: []
    },
    {
        to: '/calendar',
        icon: Users,
        label: 'Calendar',
        paths: []
    },
    {
        to: '/my-agreements',
        icon: MyAgreements,
        label: 'My Agreements',
        paths: []
    },
    {
        to: '/support',
        icon: Support,
        label: 'Support',
        paths: []
    },
]

export const PROFILE_ITEMS = [
    {
        to: '/profile',
        icon: FiHome,
        label: 'My Profile',
        paths: []
    },
    {
        to: '/settings',
        icon: FiSettings,
        label: 'Settings',
        paths: []
    },
    {
        to: '/help',
        icon: FiHelpCircle,
        label: 'Help',
        paths: []
    },
    {
        to: '/legal',
        icon: FiFileText,
        label: 'Legal',
        paths: []
    },
    {
        to: '/privacy-policy',
        icon: Pin,
        label: 'Privacy Policy',
        paths: []
    },
]