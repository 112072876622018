import { Col, Form, Row } from 'react-bootstrap'
import { ProfileWrapper } from '../../../components/authentication/Default'
import { Input } from '../../../components/common/input/Input'
import { Select } from '../../../components/common/select/Select'
import { Button } from '../../../components/common/button/Button'
import { getCountries, getStates } from 'country-state-picker'
import { useRef, useState } from 'react'
import { ReactComponent as UserCircle } from '../../../assets/images/user.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const INFO = {
    title: 'Your photo and location',
    description: 'Getting your account more personalized'
}

export const PhotoAndLocation = () => {

    const providerDetails = JSON.parse(window.localStorage.getItem('PROVIDER_DETAILS')) || {}

    var countries = getCountries()

    countries = countries.map(country => ({
        label: country?.name,
        value: country?.name,
        code: country?.code
    }))

    const initialCountry = providerDetails?.country ? countries.find(country => country?.value === providerDetails?.country) : null;

    var states = getStates(initialCountry?.code)?.map(state => ({
        label: state,
        value: state
    }))

    const [formData, setFormData] = useState({
        imageSrc: null,
        street: providerDetails?.street || '',
        city: providerDetails?.city || '',
        zipCode: providerDetails?.zipCode || '',
        selectedState: states?.find(state => state.value === providerDetails?.state) || null,
        selectedCountry: initialCountry,
    })
    states = getStates(formData?.selectedCountry?.code)?.map(state => ({
        label: state,
        value: state
    }))

    const [errors, setErrors] = useState({
        country: '',
        state: '',
    });

    const fileInputRef = useRef(null);

    const navigate = useNavigate()
    const location = useLocation();

    const { email } = location.state || {}

    const handleCountryChange = (country) => {
        setFormData(prev => ({
            ...prev,
            selectedCountry: country,
            selectedState: null
        }))
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData(prev => ({
                    ...prev,
                    imageSrc: reader.result
                }))
            }
            reader.readAsDataURL(file)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        const newErrors = { country: '', state: '' };

        if(!formData?.selectedCountry) newErrors.country = 'Country is required'
        if(!formData?.selectedState) newErrors.state = 'State is required'

        setErrors(newErrors);

        if (Object.values(newErrors).every(err => err === '')) {
            var data = {}

            var formDataObj = new FormData(e.target)

            // const profileImage = formData.get('profileImage');

            // if(profileImage?.name === '' || profileImage?.size === 0) {
            //     formData.append('profileImage', '')
            // }

            formDataObj.forEach((val, key) => {
                if (key !== 'profileImage') {
                    data[key] = val
                }
            })
            window.localStorage.setItem('PROVIDER_DETAILS', JSON.stringify({ ...providerDetails, ...data }))
            const profileImage = formDataObj.get('profileImage') || ''
            navigate('/profile/resume-uploader', { state: { ...location?.state, ...data, profileImage } })
        }
    }

    return (
        <ProfileWrapper email={email} info={INFO}>
            <Form onSubmit={handleSubmit}>
                <div className='text-center mb-2'>
                    {formData?.imageSrc ? (
                        <img
                            src={formData?.imageSrc}
                            alt='Uploaded'
                            className='mb-2 rounded-circle object-fit-cover'
                            style={{ height: 96, width: 96 }}
                        />
                    ) : (
                        <UserCircle className='mb-2' />
                    )}

                    <Button
                        variant='outline'
                        onClick={(e) => {
                            e.preventDefault()
                            fileInputRef.current.click()
                        }}
                        className='d-block mx-auto py-2 rounded fw-medium'
                    >
                        {formData?.imageSrc ? 'Edit picture' : 'Upload new picture'}
                    </Button>
                    <input
                        type='file'
                        ref={fileInputRef}
                        name='profileImage'
                        className='d-none'
                        accept='image/png, image/jpeg'
                        onChange={handleFileChange}
                    />
                </div>

                <Input
                    label='Street'
                    placeholder='Your street'
                    name='street'
                    id='street'
                    htmlFor='street'
                    value={formData?.street}
                    onChange={(e) => setFormData(prev => ({ ...prev, street: e.target.value }))}
                    className='mb-2'
                />
                <Input
                    label='City'
                    placeholder='Your city'
                    name='city'
                    id='city'
                    htmlFor='city'
                    value={formData?.city}
                    onChange={(e) => setFormData(prev => ({ ...prev, city: e.target.value }))}
                    className='mb-2'
                />
                <Input
                    label='Zip Code'
                    placeholder='01-1199929292'
                    name='zipCode'
                    id='zipCode'
                    htmlFor='zipCode'
                    value={formData?.zipCode}
                    onChange={(e) => {
                        const zipCode = e.target.value.replace(/[^0-9]/g, '')
                        setFormData(prev => ({...prev, zipCode}))
                    }}
                    className='mb-2'
                />
                <Select
                    label='State'
                    name='state'
                    inputId='stateSelect'
                    htmlFor='stateSelect'
                    placeholder='Select state'
                    className='mb-2'
                    options={states}
                    value={formData?.selectedState}
                    onChange={(state) => setFormData(prev => ({...prev, selectedState: state}))}
                    error={errors?.state}
                />
                <Select
                    label='Country'
                    name='country'
                    inputId='countrySelect'
                    htmlFor='countrySelect'
                    placeholder='Select country'
                    className='mb-3'
                    options={countries}
                    value={formData?.selectedCountry}
                    onChange={handleCountryChange}
                    error={errors?.country}
                />

                <Row className='mb-1 gap-2'>
                    <Col xs={6} md={12} lg={6}>
                        <Link
                            to='/profile/tax-information'
                            className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony py-3 text-center'
                        >
                            Back
                        </Link>
                    </Col>
                    <Col><Button type='submit' className='w-100 py-3'>Continue</Button></Col>
                </Row>
            </Form>
        </ProfileWrapper>
    )
}
