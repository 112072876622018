import { ListGroup } from 'react-bootstrap'
import { Button } from '../../button/Button'
import { ReactComponent as Dollar } from '../../../../assets/images/dollar.svg'
import { FiLogOut } from 'react-icons/fi'
import { ReactComponent as UserCircle } from '../../../../assets/images/user.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../../../config/API_URL'
import { PROFILE_ITEMS } from '../../../../data/constants'
import { FaRegBell } from 'react-icons/fa'
import { MdMailOutline } from 'react-icons/md'
import { NavDropdownWrapper } from './Wrapper'
// import { googleLogout } from '@react-oauth/google'

export const ProfileDropdown = () => {
    const navigate = useNavigate()

    const { providerDetails } = useSelector(state => state.providerReducer)

    const handleLogout = () => {
        // googleLogout()
        window.localStorage.clear()
        navigate('/welcome')
    }
    return (
        <NavDropdownWrapper
            title={
                providerDetails !== null && providerDetails?.profileImage !== '' ? (
                    <img
                        src={/^(https?|ftp):\/\//.test(providerDetails.profileImage) ? providerDetails.profileImage : `${BASE_URL}/${providerDetails.profileImage}`}
                        alt='Uploaded'
                        className='rounded-circle object-fit-cover'
                        style={{ height: 35, width: 35 }}
                    />
                ) : (
                    <UserCircle style={{ height: 35, width: 35 }} />
                )}
            id='profile-dropdown'
            header={<span className='fw-semibold color-fiord font-14'>Account menu</span>}
        >
            {PROFILE_ITEMS.map(item => {
                return <ListGroup.Item action href={item.to} key={item.to}>
                    <item.icon size={16} color='#475367' style={{ fill: item.to === '/privacy-policy' && '#475367' }} />
                    <span className='ms-2 color-fiord font-14'>{item.label}</span>
                </ListGroup.Item>
            })}
            <ListGroup.Item action onClick={handleLogout}>
                <FiLogOut color='#475367' size={16} />
                <span className='ms-2 color-fiord font-14'>Log out</span>
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}

export const WalletDropdown = () => {
    return (
        <NavDropdownWrapper
            title={
                <div className='p-2 wallet-dropdown__title-wrapper'>
                    <Dollar style={{ marginBottom: 2 }} />
                    <span className='fw-medium font-14'>0.00</span>
                </div>
            }
            id='wallet-dropdown'
            header={<span className='fw-semibold color-fiord font-14'>Account Balance</span>}
        >
            <ListGroup.Item className='d-flex justify-content-between'>
                <div className='p-2'>
                    <Dollar style={{ marginBottom: 6 }} />
                    <span className='fw-semibold fs-5 color-fiord'>0.00</span>
                </div>
                <Button className='py-2' style={{ borderRadius: 12 }}>Withdraw</Button>
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}

export const NotificationDropdown = () => {
    return (
        <NavDropdownWrapper
            title={<FaRegBell size={20} color='#667185' />}
            id='notification-dropdown'
            header={
                <>
                    <p className='mb-0 fw-semibold color-fiord font-14'>Notification</p>
                    <p className='mb-0 cursor-pointer color-primary font-12 fw-semibold'>Mark as read</p>
                </>
            }
            headerClasses='d-flex align-items-center justify-content-between'
        >
            <ListGroup.Item className='font-14'>
                Notification
            </ListGroup.Item>
            <ListGroup.Item as='button' className='color-primary font-14 fw-medium mt-1'>
                View all notification
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}

export const MessageDropdown = () => {
    return (
        <NavDropdownWrapper
            title={<MdMailOutline size={20} color='#667185' />}
            id='notification-dropdown'
            header={<span className='fw-semibold color-fiord font-14'>Messages</span>}
        >
            <ListGroup.Item className='font-14'>
                Messages
            </ListGroup.Item>
            <ListGroup.Item as='button' className='color-primary font-14 fw-medium mt-1'>
                View all messages
            </ListGroup.Item>
        </NavDropdownWrapper>
    )
}