import { Form } from 'react-bootstrap'
import { Button } from '../../../components/common/button/Button'
import { useState } from 'react'
import { AuthWrapper } from '../../../components/authentication/Default'
import { useLocation, useNavigate } from 'react-router-dom'
import { showToaster } from '../../../utils/utils'
import PostRequest from '../../../services/PostRequest'
import { MyOTPInput } from '../../../components/common/my-otp-input/MyOTPInput'

export const EmailOTPVerification = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const [otp, setOtp] = useState();
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault()

    setError('');

    if (!otp) return setError('OTP is required');

    if (otp?.length === 6) {
      try {
        const response = await PostRequest('/provider/verify-otp/email', { otp: otp, ...location?.state })
        if (window.localStorage.getItem('TOKEN') || window.localStorage.getItem('AUTH_TOKEN')) {
          window.localStorage.clear()
        }
        window.localStorage.setItem('TOKEN', response.token);
        window.localStorage.setItem('AUTH_TOKEN', response.authToken);
        showToaster('Welcome back!');
        navigate('/dashboard')
      } catch (error) {
        console.log('error', error)
        showToaster(error.message, 'error')
      }
    } else {
      return setError('Please enter a valid 6-digit OTP')
    }
  }

  return (
    <AuthWrapper>
      <h5 className='welcome-heading'>Confirm Identity</h5>
      <p className='text-black mb-4'>Enter the code sent to your email to confirm your identity</p>
      <Form onSubmit={handleSubmit}>
        <MyOTPInput
          value={otp}
          onChange={setOtp}
          inputType='number'
          label='Enter OTP'
          error={error}
          wrapperClasses='hide__arrows'
        />
        <Button type='submit' className='w-100 mb-3'>Verify</Button>
      </Form>
      <Form.Check
        type='checkbox'
        label='Always remember this device'
        name='remember'
        id='remember-device'
      />
    </AuthWrapper>
  )
}
