import { Form } from 'react-bootstrap'
import { Button } from '../../../components/common/button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import './identity-confirmation.css'
import { AuthWrapper } from '../../../components/authentication/Default'
import PostRequest from '../../../services/PostRequest'
import { showToaster } from '../../../utils/utils'

export const IdentityConfirmation = () => {

  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    var data = {}
    const formData = new FormData(e.target);
    formData.forEach((val, key) => {
      data[key] = val;
    })

    if (data.identity === 'otp') {
      
      try {
        const response = await PostRequest('/provider/send-otp/email', location?.state)
        navigate('/verify-email-otp', { state: response });
      } catch (error) {
        console.log('error', error)
        showToaster(error.message, 'error')
      }

    } else if (data.identity === 'authenticator') {
      navigate('/verify-authenticator-code');
    }
  }

  return (
    <AuthWrapper>
      <h5 className='welcome-heading'>Confirm Identity</h5>
      <p className='text-black mb-4'>Enter the code sent to your email to confirm your identity</p>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Check
          type={'radio'}
          name='identity'
          id='otp'
          value='otp'
          label={`Use the OTP method`}
          className='color-grey-500'
          defaultChecked
        />
        <hr />
        <Form.Check
          type={'radio'}
          name='identity'
          id='third-party'
          value='authenticator'
          label={`Use a third-party authenticator`}
          className='mb-4 color-grey-500'
        />
        <Button type='submit' className='w-100 mb-3'>Verify</Button>
      </Form>
      <Form.Check
        type='checkbox'
        label='Always remember this device'
        name='remember'
        id='remember-device'
      />
    </AuthWrapper>
  )
}
