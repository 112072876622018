import './button.css'

export const Button = ({ children, className, variant, ...rest }) => {
  return (
    <button
      className={`${variant === 'outline' ? 'custom_button-outline' : 'custom_button'} zero_button line-height-150 ${className}`}
      {...rest}
    >
      {children}
    </button>
  )
}
