import { applyMiddleware, combineReducers, createStore } from 'redux';
import { providerReducer } from './provider/provider.reducer';
import { thunk } from 'redux-thunk';

const rootReducer = combineReducers({
    providerReducer: providerReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store