import { Form, Stack } from 'react-bootstrap'
import { ProfileWrapper } from '../../../components/authentication/Default'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../components/common/button/Button'
import { Input } from '../../../components/common/input/Input'
import { useState } from 'react'

const INFO = {
    title: <>Tell the world about <br /> yourself with your bio</>,
    description: 'Help people to get to know you at a glance'
}

export const BioInputSection = () => {

    const providerDetails = JSON.parse(window.localStorage.getItem('PROVIDER_DETAILS')) || {}

    const navigate = useNavigate()
    const location = useLocation()

    const { email } = location.state || {}

    const [bio, setBio] = useState(providerDetails?.bio || '')
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()

        setError('')

        const bioInput = e.target.elements.bio.value
        if (!bioInput || bioInput === '') return setError('Bio is required')

        var data = {}

        var formData = new FormData(e.target)

        formData.forEach((val, key) => data[key] = val)

        window.localStorage.setItem('PROVIDER_DETAILS', JSON.stringify({ ...providerDetails, ...data }))

        navigate('/profile/submit', { state: { ...location?.state, ...data } })
    }

    return (
        <ProfileWrapper email={email} info={INFO}>
            <Form onSubmit={handleSubmit}>
                <Input
                    multiline
                    label='Your Bio'
                    placeholder='Your Bio'
                    name='bio'
                    htmlFor='bio'
                    id='bio'
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    error={error}
                    rows={4}
                    className='mb-3'
                />
                <Stack direction='horizontal' gap={4}>
                    <Link
                        to='/profile/skills'
                        className='custom_button-outline zero_button d-block text-decoration-none line-height-150 w-100 color-ebony border-ebony text-center py-3'
                    >
                        Back
                    </Link>
                    <Button type='submit' className='w-100 py-3'>Continue</Button>
                </Stack>
            </Form>
        </ProfileWrapper>
    )
}
