import { ResetPasswordWrapper } from '../../../components/authentication/Default'
import { ReactComponent as Lock } from '../../../assets/images/lock.svg'
import { Button, Form } from 'react-bootstrap'
import { Button as MyButton } from '../../../components/common/button/Button'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { showToaster } from '../../../utils/utils'
import PostRequest from '../../../services/PostRequest'
import { MyOTPInput } from '../../../components/common/my-otp-input/MyOTPInput'

export const ResetPasswordOTP = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [otp, setOtp] = useState();
    const [error, setError] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault()

        setError('');

        if (!otp) return setError('OTP is required');

        if (otp?.length === 6) {
            try {
                const response = await PostRequest('/provider/verify-otp/email', { ...location?.state, otp: otp })
                navigate('/reset-password', { state: response?.providerId })
            } catch (error) {
                showToaster(error.message, 'error')
            }

        } else {
            return setError('Please enter a valid 6-digit OTP')
        }
    }

    return (
        <ResetPasswordWrapper>
            <div className='text-center'>
                <Lock className='mb-4' />
                <div className='mb-3'>
                    <h5 className='welcome-heading fs-3 line-height-125'>Reset your password</h5>
                    <p className='get-started fw-normal line-height-150 mx-auto' style={{ maxWidth: '350px' }}>Enter your email address and we'll send you password reset instructions.</p>
                </div>
            </div>
            <Form onSubmit={handleSubmit}>
                <MyOTPInput
                    value={otp}
                    onChange={setOtp}
                    inputType='number'
                    error={error}
                    wrapperClasses='hide__arrows'
                />
                <MyButton type='submit' disabled={otp?.length !== 6} className='w-100 mb-4'>Reset Password</MyButton>
            </Form>
            <div className='login-text line-height-160 color-secondary'>
                Didn't receive a code?  {' '}
                <Button variant='link' className='color-primary fs-inherit fw-inherit p-0'>Resend</Button>
            </div>
        </ResetPasswordWrapper>
    )
}
