import { ListGroup, NavDropdown } from "react-bootstrap"

export const NavDropdownWrapper = ({ title, id, header, headerClasses, children }) => {
  return (
    <NavDropdown title={title} id={id}>
      <ListGroup variant='flush'>
        <ListGroup.Item className={headerClasses}>
          {header}
        </ListGroup.Item>
        {children}
      </ListGroup>
    </NavDropdown>
  )
}
