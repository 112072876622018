import { FETCH_PROVIDER_DETAILS } from './provider.action'

const initialState = {
    providerDetails: null
}

export const providerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROVIDER_DETAILS:
            return { ...state, providerDetails: action.value }
        default:
            return state
    }
}